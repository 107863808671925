import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SiteText from '../components/sitetext';

const Offers = () => {
  const site = useStaticQuery(graphql`
    query offersDataQuery
    {
      ersterTeil: wordpressPage(wordpress_id: { eq: 34 }) {
        title
        content
    },
    zweiterTeil: wordpressPage(wordpress_id: { eq: 57 }) {
      content
    },
      file(relativePath: { eq: "IMG_0250.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    }
    `);

  return (
    <Layout title="Angebote">
      <SiteText>
        <h1>{site.ersterTeil.title}</h1>
        <div id="mainText" dangerouslySetInnerHTML={{ __html: site.ersterTeil.content }} />
        <center>
          <Img fluid={site.file.childImageSharp.fluid} style={{ height: '64%', width: '80%', marginTop: '15px' }} />
          <p style={{ fontSize: '15px' }}>Mit unserer Expertise und Erfahrung, sind wir Ihr perfekter Partner in Sachen Videoproduktion</p>
        </center>
        <div id="mainText" dangerouslySetInnerHTML={{ __html: site.zweiterTeil.content }} />
      </SiteText>
    </Layout>
  );
};

export default Offers;
